import React, { useState } from "react";
import boleto from "../assets/Envio-de-Boletos.gif"
import agendamento from "../assets/Agendamentos.gif"
import envioArquivos from "../assets/Envioarquivos.gif";
import retangulo from "../assets/Retangulo1_11zon.webp";

const Smartphone = ({ openContatoModal }) => {
  const [currentMedia, setCurrentMedia] = useState(boleto);
  const [activeButton, setActiveButton] = useState(0);



  const handleButtonClick = (index) => {
    setActiveButton(index);
    switch (index) {
      case 0:
        setCurrentMedia(boleto);
        break;
      case 1:
        setCurrentMedia(agendamento);
        break;
      case 2:
        setCurrentMedia(envioArquivos);
        break;
      default:
        setCurrentMedia(boleto);
    }
  };

  return (
    <div className="flex items-center justify-center pt-12 mm:pt-24 h-auto lg:h-[800px] relative">
      <div className="flex items-center justify-center w-[90%] lg:w-[85%] z-10">
        <div className="flex flex-col-reverse gx:flex-row items-center justify-center rounded-lg">
          <div className="flex mm:flex-col gx:flex-col gap-4 lg:mr-[24px] gx:mr-[12px] xl:mr-[24px] mm:mb-6 gx:mb-0">
            <button
              onClick={() => handleButtonClick(0)}
              className={`py-1 px-6 w-auto sm:w-[350px] md:w-[400px] mm:shadow-bblue lg:shadow-none lg:w-auto mm:text-lg sm:text-xl lg:text-[24px] font-medium flex justify-center items-center rounded-full leading-normal font-Mont transition-all duration-500 ease-in-out  ${
                activeButton === 0
                  ? " bg-[#1e4053]  text-white"
                  : " bg-white   text-[#00649C] "
              }`}
            >
              Envio de boletos
            </button>
            <button
              onClick={() => handleButtonClick(1)}
              className={`py-1 px-6 w-[300px] sm:w-[350px] md:w-[400px] mm:shadow-bblue lg:shadow-none lg:w-auto text-base mm:text-lg sm:text-xl lg:text-[24px] font-medium flex justify-center items-center rounded-full font-Mont transition-all duration-500 ease-in-out ${
                activeButton === 1
                  ? " bg-[#1e4053] text-white"
                  : " bg-white text-[#00649C] "
              }`}
            >
              Agendamento
            </button>
            <button
              onClick={() => handleButtonClick(2)}
              className={`py-1 px-6 w-[300px] sm:w-[350px] md:w-[400px] mm:shadow-bblue lg:shadow-none lg:w-auto text-base mm:text-lg sm:text-xl lg:text-[24px] font-medium flex justify-center items-center rounded-full leading-normal font-Mont transition-all duration-500 ease-in-out ${
                activeButton === 2
                  ? " bg-[#1e4053] text-white"
                  : " bg-white text-[#00649C] "
              }`}
            >
              Atendimento
            </button>
          </div>

          <div className="relative w-[280px] sm:w-[300px] lg:w-[300px] xl:w-[320px] h-[550px] sm:h-[600px] lg:h-[600px] xl:h-[650px] gx:mr-[12px] flex-shrink-0 mb-8 gx:mb-0">
            <img
              key={currentMedia}
              src={currentMedia}
              alt="Phone frame"
              className="absolute inset-0 w-full h-full z-10 pointer-events-none "
              width="320"
              height="650"
            />
          </div>
          <div className="flex flex-col mm:w-full gx:w-[40%] ml-0 xx:ml-6 gap-y-4 items-center justify-center lg:items-start">
            <div className="w-auto lg:text-left lg:w-auto flex flex-col mb-4 items-center">
              <div className="text-[#00649C] dark:text-sky-500 text-2xl mm:text-center lg:text-left mm:text-[30px] sm:text-[32px] gx:text-[40px] xl:text-[50px] font-bold font-Maven leading-tight lg:leading-[50.20px] gx:leading-none lg:mb-[24px] w-full">
                Clique e veja como funcionam 
                as <span className="text-[#02456A] font-Mont">interações</span>
              </div>
              <div className="text-[18px] font-Inter_Normal mm:text-center lg:text-left mb-[48px] mm:hidden gx:flex text-[#656565] dark:text-white w-full">
                A MegaZap oferece diversas formas de criar interações eficazes
                com seus clientes. Entre as funcionalidades, destacam-se o envio
                de boletos, a recepção e organização dos atendimentos, e o
                agendamento. Essas ferramentas permitem que as empresas melhorem
                a comunicação e o relacionamento com seus clientes.
              </div>
              <button
                onClick={openContatoModal}
                className="bg-[#0098D9] uppercase font-Mont font-bold lg:text-[24px] gx:text-[20px] xl:text-[24px] rounded-full text-white flex justify-center mm:hidden gx:flex items-center gap-4 w-full lg:p-2 gx:px-2 gx:py-1 xl:p-2"
              >
                saiba mais...
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        src={retangulo}
        alt=""
        className="absolute mm:bottom-4 mm:left-0 gx:left-[-100px] xl:left-0 mm:h-[30%] md:h-[70%] gx:h-[100%] z-0"
        width="750" 
        height="300" 
      />
    </div>
  );
};

export default Smartphone;
