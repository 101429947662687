import React, { useEffect }  from "react";
import imgCover from "../assets/imgCover.webp"
import imgCoverM from "../assets/imgCoverM.webp"

const Cover = ({ openContatoModal }) => {
 

  useEffect(() => {
    const img = new Image();
    img.src = imgCoverM;
  }, []);
  
  return (
    <div className="pb-[8%] mm:mt-28 lg:pt-8 mm:px-6 relative lg:px-[7%]">
      <div className="flex items-center justify-center">
        <img src={imgCover} alt="imagem da plataforma" width={2000} height={2000} className="hidden w-[80%] lg:block" />
        <img src={imgCoverM} alt="imagem da plataforma mobile" className="lg:hidden" loading="lazy" />
      </div>
      <div>
        <div className="flex flex-col justify-center items-center lg:mt-[-150px] ">
          <div className="flex justify-center items-center flex-col w-[100%] lg:[w-85%]">
            <div className="text-[24px] sm:text-[28px] md:text-[40px] lg:text-[48px] xl:text-[56px] text-[#00649C] dark:text-sky-500 font-Maven font-black text-center leading-6 lg:leading-[60px]">
            A <span className="text-[#02456A]">MegaZap</span> simplifica os <br className="mm:hidden lg:block" />
            atendimentos da sua <span className="text-[#02456A]">empresa</span>!
            </div>
            <div className="text-[12px] sm:text-[15px] md:text-[16px] lg:text-[24px] mm:w-full lg:w-[80%] text-[#656565] dark:text-white font-Inter_Normal font-medium my-[10px] sm:my-[24px] text-center">
              Descubra como a MegaZap pode <br className="lg:hidden"/> revolucionar sua <span className="font-bold">gestão de atendimento</span> <br /> <span className="hidden lg:block">permitindo que sua empresa alcance resultados extraordinários.</span>
            </div>
              <button onClick={openContatoModal} className="text-white font-Mont font-extrabold lg:text-[24px] mm:text-[16px] rounded-full mm:px-4 lg:px-10 py-2 uppercase bg-[#0098D9]">Fale com um especialista</button>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Cover;
