import React, {useState} from "react";
import Help from "../components/Help";
import Smartphone from "../components/Smartphone";
import CarouselInte from "../components/CarroselInte";
import Platform from "../components/Platform";
import Painel from "../components/Painel";
import Mobile from "../components/Mobile";
import Questions from "../components/Questions";
import Footer from "../components/Footer";
import { Element } from 'react-scroll';
import ContatoModal from "../components/ContatoModal";
import Canais from "../components/Canais";
import CoverNew from "../components/CoverNew";
import Form from "../components/Form"
import Help2 from "../components/Help2"
import Test from "../components/Test"
import Testimontials from "../components/Testimontials"
import Cookie from "../components/Cookies"



function Home() {

  const [showContatoModal, setShowContatoModal] = useState(false)

  const toogleContatoModal = () =>{
    setShowContatoModal(!showContatoModal)
  } ;

  return (
    <div>
      <ContatoModal show={showContatoModal} handleClose={toogleContatoModal} />
      <Test openContatoModal={toogleContatoModal}/>
      <CoverNew openContatoModal={toogleContatoModal}/>
      <Element name="cards"> 
      </Element>
      <Help />
      <Help2 openContatoModal={toogleContatoModal}/>
      <Smartphone openContatoModal={toogleContatoModal} />
      <CarouselInte />
      <Canais/>
      <Platform />  
      <Painel />
      <Mobile />
     <Testimontials/>
      <Element name="sobre">
      </Element>
      <Element name="Planos">
        <Questions />
      </Element>
      <Element name="FAQ"><Form/></Element>
      <Footer  openContatoModal={toogleContatoModal}/> 
      <Cookie/>
    </div>
  );
}

export default Home;
