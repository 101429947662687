import React from "react";

import robo2 from "../assets/robo1.webp";

const Help = ({ openContatoModal }) => {
  return (
    // Geral
    <div className="flex justify-center h-full   bg-white relative py-5">
      <div className="lg:grid mm:flex mm:flex-col-reverse gx:grid-cols-12 w-[90%] px-4 lg:w-[85%]">
        {/* Esquerda */}
        <div className="flex flex-col items-center gx:col-start-1 xl:col-start-2 lg:col-span-5 gx:items-start gx:ml-9 xl:ml-0  gx:justify-center">
          <div className="text-[#00649C] dark:text-sky-500 text-[36px] mm:text-3xl md:text-4xl lg:text-5xl gx:text-4xl xl:text-5xl lg:mb-5 font-bold font-Maven leading-tight lg:leading-[57.60px] gx:leading-none text-center lg:text-left">
            Veja como a MegaZap <br />
            <span className="text-[#02456A] font-Maven">pode te ajudar!</span>
          </div>
          <div className="text-[#656565] dark:text-white text-sm mm:text-[14px] md:text-lg lg:text-[18px] font-Inter_Normal mm:text-center font-Inter_normal leading-snug gx:leading-tight w-full gx:w-[456px] text-center gx:text-left">
            A Megazap é uma plataforma de gestão empresarial que integra canais
            de comunicação como WhatsApp, Instagram, Facebook, Telegram, site e
            e-mail em uma única tela. Com mais de 10 anos de mercado, oferece
            respostas automáticas com bot 24 horas, garantindo suporte imediato
            aos clientes.
          </div>
          <button
            onClick={openContatoModal}
            className="uppercase text-white bg-[#0098D9] rounded-full mt-5 mm:text-[16px]  lg:text-[24px] mm:px-10 lg:px-20 gx:px-10 xl:px-20 py-1 font-Mont font-bold"
          >
            Clique e saiba mais...
          </button>
        </div>
        {/* Direita */}
        <div className="flex items-center justify-center gx:items-end gx:justify-end xl:items-end xl:justify-start col-span-6 mt-8 gx:mt-0">
          <img
            src={robo2}
            alt="Imagem do robô"
            className="mm:w-[100%] lg:w-[85%] h-auto"
            width="994" 
            height="auto" 
          />
        </div>
      </div>
    </div>
  );
};

export default Help;
