import React from "react";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import Cliente from "../assets/cliente1.webp";
import Cliente2 from "../assets/cliente2.webp";
import Cliente3 from "../assets/cliente3.webp";

const Testimonials = () => {
  return (
    <div className="flex justify-center h-full pt-8 md:py-[2%] bg-gradient-to-r from-[#0098D9] to-[#005173] ">
      <div className="grid grid-cols-1 lg:grid-cols-12 w-full place-content-center  gap-y-8">
        <div className="font-bold flex justify-center mm:text-center lg:text-left gx:text-center xl:text-left text-white dark:text-sky-500 leading-tight lg:leading-[50.40px] font-Maven text-2xl lg:text-[50px] col-span-1 lg:col-span-10 lg:col-start-2">
          O que nossos clientes dizem sobre a plataforma
        </div>
        <div className="col-span-12 col-start-1 ml-0">
          <div className="flex flex-row mm:gap-2 lg:gap-10 lg:justify-center overflow-x-auto overflow-y-hidden w-full mm:pb-4 lg:pb-0 pt-8  px-2 ">
            <div className="bg-white dark:bg-slate-950 relative px-6 flex flex-col items-center mm:w-[350px] lg:w-[400px] h-[200px] shadow-bblue3 rounded-2xl flex-shrink-0">
              <div className="text-[#00649C] dark:text-white mt-4 flex-shrink-0">
                <BiSolidQuoteAltLeft size={22} />
              </div>
              <div className="flex-1">
                <div className="text-zinc-500 dark:text-white text-base text-center mt-5 flex justify-center items-center leading-none font-Inter_Normal">
                  Já são 3 anos de parceria e durante todo este tempo ainda
                  conseguimos nos surpreender com cada proposta que o MegaZap
                  apresenta para melhoria da ferramenta. {" "}
                </div>
                <div className="text-[#00649C] text-[16px] lg:text-[16px] font-bold text-center  flex justify-center items-center my-4 leading-none font-Inter_Normal">
                  Glênia Sousa - Laclise{" "}
                </div>
              </div>
              <img
                src={Cliente}
                alt="cliente"
                className="absolute h-20 right-3 top-[-30px]"
                width="auto" 
                height="80"
              />
            </div>
            <div className="bg-white dark:bg-slate-950 relative px-6 flex flex-col items-center mm:w-[350px] lg:w-[400px] h-[200px] shadow-bblue3 rounded-2xl flex-shrink-0">
              <div className="text-[#00649C] dark:text-white mt-4 flex-shrink-0">
                <BiSolidQuoteAltLeft size={22} />
              </div>
              <div className="flex-1">
                <div className="text-zinc-500 dark:text-white text-base text-center mt-5 flex justify-center items-center leading-none font-Inter_Normal">
                  O megazap nos ajudou trazendo uma organização para o nosso
                  setor, ele hoje é um programa de extrema importância para as
                  nossas vendas receptivas{" "}
                </div>
                <div className="text-[#00649C] text-[16px] lg:text-[16px] font-bold text-center  flex justify-center items-center my-4 leading-none font-Inter_Normal">
                  Maurilio - Líder Comercial do Portal Concursos{" "}
                </div>
              </div>
              <img
                src={Cliente2}
                alt="cliente"
                className="absolute h-20 right-3 top-[-30px]"
                  width="auto" 
                height="80"
              />
            </div>
            <div className="bg-white dark:bg-slate-950 relative px-6 flex flex-col items-center mm:w-[350px] lg:w-[400px] h-[200px] shadow-bblue3 rounded-2xl flex-shrink-0">
              <div className="text-[#00649C] dark:text-white mt-4 flex-shrink-0">
                <BiSolidQuoteAltLeft size={22} />
              </div>
              <div className="flex-1">
                <div className="text-zinc-500 dark:text-white text-base text-center mt-5 flex justify-center items-center leading-none font-Inter_Normal">
                  Eu consigo com o Sistema da MegaZap, algo muito maior que
                  controlar e acompanhar as conversar nos whatsapp, hoje faço a
                  gestão completa da minha carteira de clientes{" "}
                </div>
                <div className="text-[#00649C] text-[16px] lg:text-[16px] font-bold text-center  flex justify-center items-center my-4 leading-none font-Inter_Normal">
                  Fernanda Dantas - Grupo Nandices{" "}
                </div>
              </div>
              <img
                src={Cliente3}
                alt="cliente"
                className="absolute h-20 right-3 top-[-30px]"
                  width="auto" 
                height="80"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
