import React, { useEffect, useRef, useState } from "react";
import cobrefacil from "../assets/img-carrosel/cobrefacil.webp";
import googlecalendar from "../assets/img-carrosel/googlecalendar.webp";
import hubsoft from "../assets/img-carrosel/hubsoft.webp";
import moskit from "../assets/img-carrosel/moskit.webp";
import nimbly from "../assets/img-carrosel/nimbly.webp";
import pipedrive from "../assets/img-carrosel/pipedrive.webp";
import radiusnet from "../assets/img-carrosel/radiusnet.webp";
import RDcrm from "../assets/img-carrosel/RDstationCRM.webp";
import RDmark from "../assets/img-carrosel/RDstationmarketing.webp";
import sga from "../assets/img-carrosel/SGA.webp";
import sendpulse from "../assets/img-carrosel/sendpulse.webp";
import bitrix24 from "../assets/img-carrosel/bitrix24.webp";
import superlogica from "../assets/img-carrosel/superlogica.webp";
import calendly from "../assets/img-carrosel/calendly.webp";

const CarouselInte = () => {
  const [slidesToShow, setSlidesToShow] = useState();
  const carouselRef = useRef(null);

  const slides = [
    <div className="flex items-center justify-center rounded-lg shadow-bblue bg-white  px-2  text-white h-24 w-full ">
      <img src={cobrefacil} alt="" className="object-contain h-8" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={googlecalendar} alt="" className="object-contain h-[64px]" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={hubsoft} alt="" className="object-contain h-10" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={moskit} alt="" className="object-contain h-12" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={nimbly} alt="" className="object-contain h-10" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white text-white h-24 w-full">
      <img src={pipedrive} alt="" className="object-contain h-[34px]" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={radiusnet} alt="" className="object-contain h-9" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={RDcrm} alt="" className="object-contain h-9" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={RDmark} alt="" className="object-contain h-9" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white   text-white h-24 w-full">
      <img src={bitrix24} alt="" className="object-contain h-9" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white   text-white h-24 w-full">
      <img src={sga} alt="" className="object-contain h-10" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white   text-white h-24 w-full">
      <img src={sendpulse} alt="" className="object-contain h-[70px]" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={superlogica} alt="" className="object-contain h-[22px]" />
    </div>,
    <div className="flex items-center justify-center rounded-lg shadow-bblue  px-2 bg-white  text-white h-24 w-full">
      <img src={calendly} alt="" className="object-contain h-9" />
    </div>,
  ];

  const duplicatedSlides = [...slides, ...slides];

  const updateSlidesToShow = () => {
    if (window.innerWidth < 640) {
      setSlidesToShow(6);
    } else if (window.innerWidth < 1024) {
      setSlidesToShow(8);
    } else {
      setSlidesToShow(10);
    }
  };

  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;
    const slideWidth = carousel.offsetWidth / slidesToShow;
    let start = 0;

    const animate = () => {
      start -= 0.5;

      if (Math.abs(start) >= slideWidth * slides.length) {
        start = 0;
      }

      carousel.style.transform = `translateX(${start}px)`;
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  }, [slidesToShow, slides.length]);

  return (
    <div className="relative flex font-Maven flex-col justify-center items-center gap-y-4 w-full h-30 pb-2 overflow-hidden mm:mt-[8%] gx:mt-12">
      <div className="w-full text-center mm:px-[6px] lg:px-0 lg:text-left gx:text-center xl:text-left lg:w-[800px] mt-10 text-[#00649C] dark:text-sky-500 text-2xl mm:text-3xl sm:text-4xl lg:text-4xl font-bold font-Maven leading-tight lg:leading-[43.20px]">
        <span className="text-[#02456A]">Integração</span> com as melhores
        plataformas
      </div>{" "}
      <div className="flex justify-between items-center">
        <div className="relative flex items-center bg-green-90 w-full h-40 overflow-hidden">
          <div
            ref={carouselRef}
            className="flex transition-transform duration-1000 ease-linear space-x-5"
            style={{
              width: `calc(${(duplicatedSlides.length * 100) / slidesToShow}%)`,
            }}
          >
            {duplicatedSlides.map((slide, index) => (
              <div
                key={index}
                className="flex-shrink-0 flex items-center justify-center"
                style={{
                  width: `calc(100% / ${slidesToShow} - 0.5rem)`,
                  height: "100%",
                }}
              >
                {slide}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselInte;
