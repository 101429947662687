import React from "react";
import cel1 from "../assets/cel1.webp";
import appstore from "../assets/AppStore.png";
import playstore from "../assets/PlayStore.png";

const Mobile = () => {
  return (
    <div className="flex justify-center h-full mt-8 md:mt-[4%]">
      <div className="grid grid-cols-1 lg:grid-cols-12 w-[90%] md:w-[85%]">
        {/* Esquerda */}
        <div className="lg:col-span-5 lg:col-start-2 w-full  flex flex-col gap-5">
          <div className="font-Maven font-bold lg:text-left mm:text-center text-[27px] xl:text-[42px] xx:text-[50px] lg:text-[50px] gx:text-[36px] text-[#00649C] dark:text-sky-500 leading-tight lg:leading-none">
            Mantenha-se conectado <br className="hidden lg:flex" /> com nosso{" "}
            <span className="text-[#02456A] font-Maven">App Mobile.</span>
          </div>
          <div className="font-Inter_Normal hidden lg:block font-normal lg:text-left mm:text-center mm:text-[12px] lg:text-base text-[#171717] dark:text-white leading-tight">
            Acesse nossa plataforma onde quer que você esteja. Com o App Mobile
            da Megazap, gerenciar seus atendimentos nunca foi tão fácil, esteja
            você no escritório ou em movimento.
          </div>

          <div className="text-[#00649C] hidden lg:block  lg:text-left mm:text-center dark:text-sky-500 font-Maven text-[22px] md:text-[34px] lg:text-[50px] gx:text-[34px] xl:text-[42px] xx:text-[50px] font-bold leading-tight lg:leading-none">
            Controle total, <br className="hidden lg:flex" /> na palma da sua
            mão!
          </div>

          <div className="flex gap-4 lg:justify-start mm:justify-center gx:mb-10 xl:mb-0">
            <a href="https://apps.apple.com/br/app/megazap/id6502463814?platform=ipad" target="_blank" rel="noreferrer">
              <img
                src={appstore}
                alt="app store"
                className="cursor-pointer"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=mz.workspace&pcampaignid=web_share" target="_blank" rel="noreferrer">
              <img
                src={playstore}
                alt="play store"
                className="cursor-pointer"
              />
            </a>
          </div>
        </div>
        {/* Direita */}
        <div className="flex justify-center mm:h-auto lg:h-auto lg:items-end lg:col-span-5 lg:col-end-12 border-black mt-8 md:mt-0 ">
          <img
            src={cel1}
            alt="imagem de celular"
            aria-label="imgcel"
            className={`mm:w-[85%] sm:w-[40%] md:w-[30%] lg:w-[85%] gx:w-full xl:w-[85%] rounded-2xl  transition-opacity duration-1000 ease-in-out hover:opacity-80`}
            width="994" 
            height="auto" 
          />
        </div>
      </div>
    </div>
  );
};

export default Mobile;
