import React from "react";
import polygono1 from "../assets/Polygon1.webp";
import polygono2 from "../assets/Polygon2.webp";

const Help = () => {
  return (
    <div className="flex justify-center h-full mt-4 mm:mt-8 bg-gradient-to-r from-[#095A85] to-[#0196D6] relative gap-10 py-10">
      <div className="grid grid-cols-1 gx:grid-cols-12 w-full px-4 xl:w-[85%]">
        {/* Esquerda */}
        <div className="flex flex-col items-center gx:col-start-2 lg:col-span-4 gx:items-start gx:ml-7 xl:ml-0 gx:justify-center">
          <div className="text-white dark:text-sky-500 text-[36px] mm:text-3xl md:text-4xl lg:text-5xl gx:text-4xl xl:text-5xl mb-5 font-bold font-Maven leading-tight lg:leading-[57.60px] gx:leading-none text-center lg:text-left">
            Conheça o <br className="mm:hidden lg:flex" />
            painel gestor
          </div>
         
          <div className="text-white dark:text-white text-sm mm:text-[14px] md:text-lg lg:text-[18px] font-Inter_Normal font-light leading-snug gx:leading-tight w-auto max-w-[450px] text-center gx:text-left">
          Ferramenta poderosa que permite aos gestores acompanhar todas as interações dos colaboradores, enviar mensagens diretamente aos clientes e de forma privada aos atendentes. Além disso, oferece uma visão clara e detalhada das métricas de desempenho, facilitando a avaliação e otimização dos atendimentos.
          </div>
        </div>
        {/* Direita */}
        <div className="flex items-center justify-center gx:items-end gx:justify-end xl:items-end xl:justify-start col-span-6 mt-8 gx:mt-0">
          <video
            src="https://v2b-19.mz-css.net/8363a53ed816c483c6c4f44ef177f560/dash1.webm"
            poster="https://v2b-20.mz-css.net/b2616f2ae50fb197350ccd4ee064150d/capa-video-painel-gestor.webp"
            controls
            className="mm:rounded-[20px] lg:rounded-[40px] cursor-pointer"
          ></video>
        </div>
      </div>
      <img src={polygono1} alt="poligono" className="absolute bottom-0 right-4 mm:w-[15%] lg:w-[10%]" />
      <img src={polygono2} alt="poligono2" className="absolute top-0 left-4 mm:w-[15%] lg:w-[10%]" />
    </div>
  );
};

export default Help;
