// FormWithTitle.jsx
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import { ClipLoader } from "react-spinners";

const FormWithTitle = () => {
  const [nameClient, setNameClient] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [utmParams, setUtmParams] = useState({
    utmCampaign: "",
    utmContent: "",
    utmMedium: "",
    utmSource: "",
    utmTerm: "",
  });
  const [currentId, setCurrentId] = useState("5513997020038");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUtmParams({
      utmCampaign: urlParams.get("utm_campaign") || "",
      utmContent: urlParams.get("utm_content") || "",
      utmMedium: urlParams.get("utm_medium") || "",
      utmSource: urlParams.get("utm_source") || "",
      utmTerm: urlParams.get("utm_term") || "",
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

 

  const handleSubmit3 = (data) => {
    if (!validatePhoneNumber(number)) {
      return;
    }

    setSubmitting(true)

    const dados = {
      channel: {
        type: "WHATSAPP",
        id: currentId,
      },
      id: number,
      name: nameClient,
      lead: true,
      fields: [
        { name: "email", value: email },
        { name: "fonte", value: process.env.REACT_APP_FONTE_LEAD },
        { name: "utmCampaign", value: utmParams.utmCampaign || "" },
        { name: "utmContent", value: utmParams.utmContent || "" },
        { name: "utmMedium", value: utmParams.utmMedium || "" },
        { name: "utmSource", value: utmParams.utmSource || "" },
        { name: "utmTerm", value: utmParams.utmTerm || "" },
        { name: "formSite", value: "Sim" },
      ],
    };

    fetch("https://service.apidoc.com.br/api/megazap/subir-lead", {
      body: JSON.stringify(dados),
      method: "POST",
      headers: {
        "customer-token": "b7d92abd-f806-44be-8542-4ace008a751c",
        "Content-Type": "application/json",
      },
    }).then(
      (response) => {
       
        if (response.status === 200) {
          setSubmitting(false)
          Swal.fire({
            icon: "success",
            title: "Obrigado pelo contato! 😊",
            text: "Recebemos seu contato, estamos te redirecionando para o WhatsApp.",
            showConfirmButton: false,
            timer: 1000,
          });
          setTimeout(() => {
            setNameClient("");
            setNumber("");
            setEmail("");
            const message = encodeURIComponent(
              "Olá, gostaria de saber mais sobre a MegaZap!"
            );
            const redirectUrl = `https://wa.me/+${currentId}?text=${message}`;
            redirectToWhatsApp(redirectUrl);
            // Alterna o valor do currentId após o redirecionamento
            setCurrentId((prevId) =>
              prevId === "5513997020038" ? "551335007939" : "5513997020038"
            );
          }, 1000);
        } else {
          setNameClient("");
          setNumber("");
          setEmail("");
          const message = encodeURIComponent(
            "Olá, gostaria de saber mais sobre a MegaZap!"
          );
          const redirectUrl = `https://wa.me/+${currentId}?text=${message}`;
          redirectToWhatsApp(redirectUrl);
          // Alterna o valor do currentId após o redirecionamento
          setCurrentId((prevId) =>
            prevId === "5513997020038" ? "551335007939" : "5513997020038"
          );
        }
      },
      (err) => {
        
        console.log("FAILED...", err);
        setSubmitting(false)
        Swal.fire({
          icon: "error",
          title: "Algo deu errado, tente novamente 😢",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    );
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^55\d{2}9\d{8}$/;
    if (!phoneRegex.test(value)) {
      setError("number", {
        type: "manual",
        message: "Número de telefone inválido.",
      });
      return false;
    }
    clearErrors("number");
    return true;
  };

  const redirectToWhatsApp = (url) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };
  return (
   <>
   {
  submitting && (
    <div className="fixed h-screen inset-0 flex items-center justify-center z-50 bg-[#c7c7c780] bg-opacity-30">
      <ClipLoader size={50} color={"#123abc"} loading={submitting} />
    </div>
  )
}
     <div className="flex flex-col md:flex-row mm:justify-center lg:justify-between items-start p-4 bg-white shadow-md rounded-md mm:h-auto mm:py-20 lg:py-0 lg:h-[818px] bg-formbackground bg-no-repeat bg-center bg-cover mt-10">
      {/* Título */}
      <div className="w-full  md:w-1/2 mb-4 md:mb-0 flex items-center justify-end h-fit lg:h-full leading-none">
        <h1 className="mm:text-[28px] md:text-[34px] lg:text-[34px] gx:text-[50px] font-bold font-Maven text-white text-center mm:w-full lg:w-[70%]">
        Vamos conversar! <br />
        <span className="font-normal leading-none mm:text-[18px] md:text-[24px] lg:text-[24px] gx:text-[40px] font-Maven">Preencha o formulário <br />
        e entraremos em contato.</span>
        </h1>
      </div>

      {/* Formulário */}
      <div className="w-full md:w-1/2 flex items-center mm:justify-center lg:justify-start h-fit lg:h-full">
        <form onSubmit={handleSubmit(handleSubmit3)} className="space-y-4 mm:w-full lg:w-[70%]">
          <div>
            <input
              type="text"
              value={nameClient}
              id="nameClient"
               autoComplete="nameClient"
              name="nameClient"
              className="mt-1 py-3 px-4 block w-full border border-gray-300 rounded-md shadow-sm  sm:text-sm"
              placeholder="Digite seu nome"
              onChange={(e) => setNameClient(e.target.value)}
            />
             {errors.nameClient && (
                  <span className="text-red-700 absolute">
                    {errors.nameClient.message}
                  </span>
                )}
          </div>

          <div>
            <input
             {...register("email", {
              required: "Ops, email inválido. 🚫",
            })}
              type="email"
              id="email"
              autoComplete="email"
              name="email"
              className="mt-1 py-3 px-4 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Digite seu melhor E-mail!"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            {errors.email && (
                  <span className="text-red-700 absolute">
                    {errors.email.message}
                  </span>
                )}
          </div>

          <div>
          <PhoneInput
        country={'br'}
        value={number}
        onChange={(phone) => {
          setNumber(phone);
          validatePhoneNumber(phone);
        }}
        inputStyle={{
          width: '100%',
          height: '44px', 
          borderRadius: '0.375rem', 
          fontSize: '1rem', 
          borderColor: '#cbd5e1',
          transition: 'border-color 0.3s ease', 
        }}
        placeholder="+55 99 9 99999999"
        autoFormat={true}
        disableCountryCode={false}
        type="tel"
      />
       
          </div>

          <div>
            <button
              className="w-full font-Mont font-bold inline-flex justify-center py-1 px-4 border border-transparent cursor-pointer shadow-sm text-[24px] uppercase rounded-full text-[#00649C] bg-white"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
   </>
  );
};

export default FormWithTitle;
