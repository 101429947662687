import React from 'react';



const Painel = () => {

    return (
        <div className='flex flex-col justify-center items-center h-full p-4 gx:bg-transparent mt-[8%] lg:mt-[2%]'>
            
            <div className='grid grid-cols-1 md:grid-cols-12 w-full md:w-[85%] gap-4'>
            

                <div className='flex flex-row justify-center items-center col-span-10 md:col-end-12 h-auto mm:gap-2 lg:gap-5'>
               
                </div>
                <div className="col-span-10 md:col-end-12 lg:my-4">
                <h2 className="text-[#00649C] text-[36px] mm:text-3xl md:text-4xl lg:text-5xl mb-5 font-bold font-Maven leading-tight lg:leading-[57.60px] text-center ">
            Um pouco sobre 
            a <span className='text-[#02456A]'>MegaZap</span>
          </h2>
                <p className="text-[#656565] text-sm mm:text-[14px] md:text-lg lg:text-[18px] font-Inter_Normal font-normal leading-snug gx:leading-tight w-auto  text-center ">
            Há 10 anos no mercado e líder no seu segmento, a MegaZap tem sede em Nossa Senhora da Glória/SE e uma filial em Santos/SP, sempre com o objetivo de oferecer as melhores soluções em tecnologia, a empresa vem se destacando no cenário nacional como uma das maiores no seu segmento além de apresentar ferramentas revolucionárias para gerenciar os atendimentos.
          </p>
                </div>
                <div className="col-span-10 md:col-end-12 border-black ">
                    <video
                        src="https://v2b-24.mz-css.net/3cc1e7272ee51312ac2a752385530513/video_mznew.mp4"
                        controls
                        loop
                        className="w-full h-auto rounded-2xl cursor-pointer"
                        poster="https://v2b-19.mz-css.net/4050de30d4e971c68c637b90582106b4/capa_megazap.webp"
                    />
                    
                </div>
            </div>
        </div>
    );
};

export default Painel;
