import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import Tela from "../assets/screens/tela1.webp";
import Bola from "../assets/bola.webp";

const Canais = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (inView) {
      setStartAnimation(true);
    }
  }, [inView]);

  return (
    <div className="lg:h-auto mm:h-auto relative flex justify-center mm:pt-[8%] lg:pt-[4%] flex-col pb-[2%]">
      <div className="text-[#00649C] dark:text-sky-500  font-bold mm:text-[32px] mx-4 md:text-3xl lg:text-[50px] mm:leading-none lg:leading-[50px] font-Maven text-center pb-[12px] ">
        Chega de{" "}
        <span className="text-[#02456A] font-Maven">
          várias <br className="block lg:hidden" /> abas
        </span>{" "}
        no navegador
      </div>
      <div className="text-center font-Inter_Normal text-[#656565] pb-[40px] text-[18px] mm:hidden lg:block">
      Com a nossa plataforma ominichannel todos os seus atendimentos
      centralizados em apenas uma tela com integração da API Oficial do whatsapp bussines!
      </div>

      <div className="w-full h-[50%] flex justify-center">
        <div className="flex mm:justify-center lg:justify-end items-center lg:gap-x-4 gx:gap-x-8 h-full mm:w-[90%] lg:w-[60%] relative">
          <img
            src={Bola}
            alt="bola"
            width="80"
            height="auto"
            className=" mm:w-[40%] sm:w-[20%] lg:w-[25%] h-auto mm:absolute bottom-[-50px] lg:static flex z-20 pt-20 hover:scale-110 transition-all duration-500 ease-in-out"
          />
          <img
            src={Tela}
            alt="tela"
            className="mm:w-[100%]  lg:w-[70%] rounded-xl h-auto z-10"
            width="320" 
            height="auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Canais;
