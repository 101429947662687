import React from "react";
import {
  Clipboard,
  Monitor,
  LineChart,
  Headset,
  Clock,
  Users,
  Tag,
  MessageSquare,
  Bot,
  ArrowDownUp,
  Trophy,
} from "lucide-react";
import { MdOutlineGroups } from "react-icons/md";
import tela3 from "../assets/screens/tela3.webp";
import tela4 from "../assets/screens/tela4.webp";

const Platform = () => {
  return (
    <div className="hidden gx:block h-auto bg-gradient-to-br from-[#161616] via-[#292929] to-[#3F3F3F] pb-[2%]">
      {/* Cima */}
      <div className="w-full flex text-center py-10 xx:pt-[5%] justify-center text-white md:text-[42px] xl:text-[50px] mm:text-[24px] mm:leading-5 font-bold font-Maven md:leading-[46.20px]">
        Funcionalidades que você só terá com a MegaZap
      </div>

      <div className="flex justify-center items-center  mt-10">
        <div className="grid grid-cols-1 items-center xx:grid-cols-12 w-full md:w-[70%] gap-6 md:gap-3 h-auto ">
          {/* Esquerda */}
          <div className="flex mm:flex-col lg:flex-row xx:flex-col lg:mb-10 xx:mb-0 justify-center mm:items-center xx:items-end h-auto gap-3 col-span-12 xx:col-span-3">
            {/* Cards */}
            <div className="bg-white dark:bg-slate-950 flex justify-center items-center w-[300px] py-4 px-3 lg:min-h-[70px] xx:min-h-px md:w-[324px] shadow-bblue3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
              <div className="flex items-center w-full gap-1">
                <div className="text-[#0369A1] dark:text-sky-500  ">
                  <Clipboard />
                </div>
                  <div className="flex items-center justify-center">
                    <div className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none  font-Inter_Normal">
                      Distribuição de Atendimento
                    </div>
                  </div>
              </div>
            </div>
            <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] py-4 px-3 lg:min-h-[70px]  xx:min-h-px  md:w-[324px] shadow-bblue3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
              <div className="flex w-full items-center gap-1">
                <div className="text-[#0369A1] dark:text-sky-500  ">
                  <Monitor />
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none  font-Inter_Normal">
                    CRM
                  </span>
                  
                </div>
              </div>
            </div>
            <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] py-4 px-3 lg:min-h-[70px] xx:min-h-px  md:w-[324px] shadow-bblue3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
              <div className="flex w-full items-center gap-1">
                <div className="text-[#0369A1] dark:text-sky-500 text[28px] ">
                  <LineChart />
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none  font-Inter_Normal">
                    Dashboard e Relatórios
                  </span>
                  
                </div>
              </div>
            </div>
          </div>
          {/* Direita */}
          <div className="mm:mt-8 lg:mt-0  col-span-12 xx:col-span-9 mm:flex mm:justify-center">
            <img
              src={tela3}
              alt=""
              className="mm:w-[320px] lg:w-[80%] xx:w-[95%] h-auto  xl:h-[400px] rounded-2xl"
            />
          </div>
        </div>
      </div>

      {/* Meio */}
      <div className="flex justify-center items-center  mt-10">
        <div className="grid grid-cols-1 items-center xx:grid-cols-12 w-full md:w-[70%] gap-6 md:gap-5 h-auto ">
          {/* Esquerda */}
          <div className=" xx:col-span-9 mm:hidden xx:flex">
            <img
              src={tela4}
              alt=""
              className="w-[98%] h-auto md:h-[400px] rounded-2xl"
            />
          </div>
          
          {/* Direita */}
          <div className="flex mm:flex-col lg:flex-row xx:flex-col lg:mb-10 xx:mb-0 justify-center mm:items-center xx:items-end h-auto w-auto gap-3 col-span-12 xx:col-start-10 xx:col-span-3">
            {/* Cards */}
            <div className="bg-white  dark:bg-slate-950 flex justify-center w-[300px] lg:min-h-[70px]  xx:min-h-px py-4 px-3  md:w-[324px] shadow-bblue3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
              <div className="flex items-center w-full gap-1">
                <div className="text-[#0369A1] dark:text-sky-500  ">
                  <Headset />
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none  font-Inter_Normal">
                    Suporte especializado
                  </span>
                 
                </div>
              </div>
            </div>
            <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] lg:min-h-[70px]  xx:min-h-px  md:w-[324px] py-4 px-3 shadow-bblue3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
              <div className="flex w-full gap-1">
                <div className="text-[#0369A1] dark:text-sky-500  ">
                  <Clock />
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none  font-Inter_Normal">
                    Mensagens Programadas
                  </span>
                  
                </div>
              </div>
            </div>
            <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] lg:min-h-[70px]  xx:min-h-px md:w-[324px] py-4 px-3 shadow-bblue3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
              <div className="flex w-full items-center gap-1">
                <div className="text-[#0369A1] dark:text-sky-500 ">
                  <Users />
                </div>
                <div className="flex justify-center items-center">
                  <div className="text-[#0369A1] dark:text-sky-500 text-[18px]    font-Inter_Normal">
                    Participantes
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
          <div className="mm:mt-8 lg:mt-0  col-span-12 xx:col-span-8 mm:flex xx:hidden mm:justify-center">
            <img
              src={tela4}
              alt=""
              className="mm:w-[320px] lg:w-[80%] xx:w-full h-auto  xl:h-[400px] rounded-2xl"
            />
          </div>
        </div>
      </div>

      {/* Baixo */}
      <div className="flex justify-center mt-[2%]">
        <div className="grid lg:grid-cols-3 mm:grid-cols-1 mm:mt-[5%] lg:mt-0  lg:grid-rows-2 md:gap-5 mm:gap-2">
          <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] md:h-full mm:h-[90%] py-4 px-3  shadow-bblue3  rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
            <div className="flex w-full gap-1 items-center">
              <div className="text-[#0369A1] dark:text-sky-500 text[28px] ">
                <Tag />
              </div>
              <div className="flex items-center justify-center">
                <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none font-Inter_Normal">
                  Etiquetas
                </span>
               
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] md:h-full mm:h-[90%] shadow-bblue3 py-4 px-3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
            <div className="flex w-full items-center gap-1">
              <div className="text-[#0369A1] dark:text-sky-500 text[28px]  ">
                <MdOutlineGroups size={20} />
              </div>
              <div className="flex items-center justify-center">
                <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none font-Inter_Normal">
                  Grupos
                </span>
               
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] md:h-full mm:h-[95%] shadow-bblue3 py-4 px-3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
            <div className="flex w-full items-center gap-1">
              <div className="text-[#0369A1] dark:text-sky-500 text[28px]">
                <MessageSquare />
              </div>
              <div className=" flex items-center justify-center">
                <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none font-Inter_Normal">
                  Chat interno
                </span>
                
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] md:h-full mm:h-[95%] shadow-bblue3 py-4 px-3  rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
            <div className="flex w-full items-center gap-1">
              <div className="text-[#0369A1] dark:text-sky-500  text[28px]  ">
                <Bot />
              </div>
              <div className=" flex items-center justify-center">
                <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none  font-Inter_Normal">
                  Bot 24 horas
                </span>
                
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] md:h-full mm:h-[95%] shadow-bblue3 py-4 px-3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
            <div className="flex w-full items-center gap-1">
              <div className="text-[#0369A1] dark:text-sky-500  text[28px]">
                <ArrowDownUp />
              </div>
              <div className=" flex items-center justify-center">
                <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none font-Inter_Normal">
                  Banco de Arquivos
                </span>
                
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-slate-950 flex justify-center w-[300px] md:h-full mm:h-[%] shadow-bblue3 py-4 px-3 rounded-[16px] transform transition-transform duration-200 group hover:scale-105">
            <div className="flex w-full items-center gap-1">
              <div className="text-[#0369A1] dark:text-sky-500 text[28px] ">
                <Trophy />
              </div>
              <div className=" flex items-center justify-center">
                <span className="text-[#0369A1] dark:text-sky-500 text-[18px] leading-none font-Inter_Normal">
                  Protocolos
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
