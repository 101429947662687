import React, { useState } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";

const FAQDropdown = () => {
  const [isOpen, setIsOpen] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const faqs = [
    
    {
      id: 2,
      question: 'A plataforma é difícil de usar?',
      answer: 'Nossa plataforma é intuitiva e fácil de usar, mesmo para iniciantes.',
    },
    {
      id: 3,
      question: 'Preciso de suporte técnico para começar?',
      answer: 'Nosso suporte especializada dará o treinamento inicial e ficará disponível para ajudar com qualquer dúvida futura.',
    },
    {
      id: 4,
      question: 'A plataforma se integra com as redes sociais?',
      answer: 'Sim, nossa plataforma se integra a: WhatsApp, Instagram, telegram, Facebook, e-mail e site',
    },
    {
      id: 5,
      question: 'Como a plataforma pode ajudar a aumentar minhas vendas?',
      answer: 'Nossa ferramenta de automação de marketing otimiza suas campanhas, aumentando a eficiência e a conversão.',
    },
    {
      id: 6,
      question: 'A plataforma é segura?',
      answer: 'Sim, utilizamos as melhores práticas de segurança para proteger seus dados.',
    },
    {
      id: 7,
      question: 'Quanto tempo leva para ver os resultados?',
      answer: 'Muitos de nossos clientes começam a ver resultados positivos nas primeiras semanas de uso.',
    },
    {
      id: 8,
      question: 'Existe algum custo oculto?',
      answer: 'Não, todos os custos são transparentes e informados antecipadamente.',
    },
  ];

  const toggleFAQ = (id) => {
    if (isOpen === id) {
      setIsOpen(null);
      setSelectedQuestionId(null); 
    } else {
      setIsOpen(id);
      setSelectedQuestionId(id); 
    }
  };

  return (
    <div className="mt-[8%] max-w-[80%] mx-auto md:max-w-[70%] sm:max-w-[90%] mm:max-w-full mm:px-4">
      <div className="font-bold mb-[4%] flex justify-center text-center text-[#00649C] dark:text-sky-500 leading-[50.40px] font-Maven text-[42px] lg:text-[50px] md:text-[36px] sm:text-[32px] mm:text-[28px]">
        Perguntas frequentes - FAQ
      </div>
      <div className="shadow-blueshadow bg-white dark:bg-slate-950 rounded-lg divide-gray-200 transition-all duration-300 ease-in-out">
        {faqs.map((faq) => (
          <div key={faq.id} className="transition-all duration-300 ease-in-out">
            <button
              type="button"
              className={`flex justify-between w-full px-4 py-2 lg:text-base mm:text-sm font-semibold font-Inter_Normal text-left rounded-lg  focus:outline-none ${
                selectedQuestionId === faq.id ? 'text-[#00649C] dark:text-sky-500 bg-blue-100 dark:bg-slate-900' : 'text-[#828282] dark:text-white bg-gray-100 dark:bg-slate-950 hover:bg-gray-200'
              }`}
              onClick={() => toggleFAQ(faq.id)}
              aria-expanded={isOpen === faq.id}
              aria-controls={`faq-${faq.id}`}
            >
              <span className='font-inter_Normal' >{faq.question}</span>
              <IoMdArrowDropdown 
                className={`ml-2 h-5 w-5 min-w-[1.25rem] min-h-[1.25rem]  transition-transform ${
                  isOpen === faq.id ? 'transform rotate-180' : ''
                }`}
              />
            </button>
            <div
              id={`faq-${faq.id}`}
              className={`px-4 py-2 lg:text-base mm:text-sm text-neutral-900 dark:text-gray-400 font-normal  font-Inter_Normal ${
                isOpen === faq.id ? 'block' : 'hidden'
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQDropdown;
