import React, { useState } from 'react';

const CookieConsent = () => {
  const [showPopup, setShowPopup] = useState(true);

  const handleAccept = () => {
    setShowPopup(false);

  };

  if (!showPopup) {
    return null;
  }

  return (
    <div className="fixed bottom-6 mx-4 rounded-2xl shadow-lg left-0 right-0 bg-gray-50 text-gray-900 p-4  w-fit justify-self-center flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4 z-50">
      <div className="text-center md:text-left">
        <p className="text-sm md:text-base">
          Nós usamos cookies para melhorar sua experiência. Ao continuar a navegar, você concorda com nossa política de cookies.
        </p>
      </div>
      <button
        onClick={handleAccept}
        className="bg-[#0098D9] hover:bg-[#2d8eb8] text-white text-sm md:text-base py-2 px-4 rounded-xl"
      >
        Aceitar
      </button>
    </div>
  );
};

export default CookieConsent;
